import { graphql, useStaticQuery } from "gatsby"
import "../stylesheets/general.css"
import "../stylesheets/services.css"
import React from "react"
import {
  Button,
  CapabilityCard,
  Card,
  Content,
  CTA,
  Hero,
  Layout,
  ServiceCard,
  Subscribe,
} from "../components"
import SEO from "../components/SEO"
import { ServiceList } from "../elements"

const capabilities = [
  {
    name: "Digital Transformation",
    listings: [
      "Change Management",
      "New Business Models",
      "Growth Strategies",
      "Product Strategy",
      "Technology Consulting",
      "Commerce Consulting",
      "Digital Consulting",
      "Ask about IPP X!",
    ],
  },
  {
    name: "Strategy",
    listings: [
      "Consumer Segmentation",
      "Customer Journey Mapping",
      "Brand Strategy",
      "Digital Strategy",
      "Big Idea Campaign Development",
    ],
  },
  {
    name: "Development",
    listings: [
      "Web & Mobile Development",
      "User Experience Design",
      "Chatbots",
      "Ecommerce Integrations",
      "Product Development",
      "Rapid Prototyping",
      "Maintenance and Support",
      "Marketing Automation",
      "Technical SEO",
      "Measurements & Optimization",
    ],
  },
  {
    name: "Creative",
    listings: [
      "Brand & Identity",
      "Design & Copy",
      "Photography",
      "Storyboarding",
      "Voiceover",
      "Animation",
      "Video Production",
      "Live Streaming",
    ],
  },
  {
    name: "Analytics",
    listings: [
      "Data Strategy",
      "Website Tracking",
      "AB Testing",
      "Paid Search",
      "Data Visualization",
    ],
  },
  {
    name: "Social",
    listings: [
      "Social Media Strategy",
      "Monitoring & Engagement",
      "Analytics & ROI Planning",
      "Community Management",
      "Campaign Management",
      "Influencer Management",
    ],
  },
  {
    name: "Experiential",
    listings: [
      "Virtual Reality",
      "Augmented Reality",
      "Emerging Experiences",
      "Digital Activations",
      "Brand Ambassadors",
      "Product Sampling",
    ],
  },
]
const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: imageSharp(
        fluid: { originalName: { eq: "services-hero.png" } }
      ) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      mt: imageSharp(fluid: { originalName: { eq: "mt.png" } }) {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid
        }
      }
      bt: imageSharp(fluid: { originalName: { eq: "bt.png" } }) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      xt: imageSharp(fluid: { originalName: { eq: "xt.png" } }) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      ctaImgDesktop: imageSharp(
        fluid: { originalName: { eq: "service-cta.png" } }
      ) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      ctaImgMobile: imageSharp(
        fluid: { originalName: { eq: "service-cta.png" } }
      ) {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  const services = [
    {
      abbreviation: "BT",
      description:
        "In a dynamic global environment Organisational Change Management and Digital Transformation strategies are an essential business service to exploit opportunities. Get in touch to learn how our agile and on demand organisational change and transformation strategies can enable your team to: Pivot in crises mode to respond and survive. Change to safeguard, leverage opportunities and create value. Transform to optimise opportunities and sustain value within the new normal.",

      name: "Business Transformation",
      image: data.bt.fluid,
    },
    {
      abbreviation: "MT",
      description:
        "Marketing Transformation is not a trend, it is the start of a new era in which the customer is taking control and challenging the status quo. Fast-paced disruption triggered by digital technology has moved engagement beyond online channels and mobile devices to strategy designed for optimal customer satisfaction and experience. It’s time to go beyond digital competence and embrace transformation. Let us help you move from intent to action to repeatable success and growth.",
      name: "Marketing Transformation",
      image: data.mt.fluid,
    },
    {
      abbreviation: "XT",
      description:
        "Digital technology is shaping the way people shop and discover; consumers are choosing connections over commercials. IPP has invested time and technology mastering the art of positioning your products and services for impressions and impact. Our team has worked with new and well-established brands to transform customer experiences, getting brands into the homes and hearts of customers. We convert fun experiences and exciting engagement into valuable data to drive the development of even more innovative, creative, and meaningful solutions for your customers.",
      name: "Experience Transformation",
      image: data.xt.fluid,
    },
  ]
  const ctaImages = [
    data.ctaImgMobile.fluid,
    {
      ...data.ctaImgDesktop.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return (
    <Layout>
    <SEO title={"Services"} description={"Digital Transformation is our business and the key to your long term growth strategy."} />
      <Hero
        title="Solutions Designed For Success."
        image={data.heroImage.fluid}
        description="Digital Transformation is our business and the key to your long term growth strategy."
      />
      <Content spacing>
        <Card
          name="Trusted By Leading Brands."
          margin="0 0 82px 0"
          title="We engineer solutions to support companies at all stages of growth across industries."
          description="Welcome to the home of strategy, design, and technology solutions to support ambitious businesses and brands. Using a design-driven and data-focused approach, we build cutting-edge digital solutions and deliver compelling content and experiences to our clients and their customers."
        />
        <ServiceList>
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              index={index}
              name={service.name}
              image={service.image}
              abbreviation={service.abbreviation}
              description={service.description}
            />
          ))}
        </ServiceList>
        <Card margin="100px 0 0 0" grid="2x2" name="Capabilities.">
          {capabilities.map((capability, index) => (
            <CapabilityCard key={index} capability={capability} />
          ))}
        </Card>
      </Content>

      <Subscribe />
    </Layout>
  )
}
export default ServicesPage
